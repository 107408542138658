import "./src/styles/global.css";

import React from "react";
import { AnimatePresence } from "framer-motion";

export const wrapPageElement = ({ element, props }) => (
  <AnimatePresence exitBeforeEnter>{element}</AnimatePresence>
);

// const addScript = (url) => {
//   const script = document.createElement("script");
//   script.src = url;
//   script.async = true;
//   document.body.appendChild(script);
// };
// export const onClientEntry = () => {
//   window.onload = () => {
//     addScript(
//       `https://webfont.fontplus.jp/accessor/script/fontplus.js?mngV5R97MNQ%3D&box=LacmM-oidHI%3D&aa=1&ab=2`
//     );
//   };
// };
